export const initializeAddress = 'initializeAddress';
export const validateCountry = 'validateCountry';
export const validateAddress = 'validateAddress';
export const validateEmail = 'validateEmail';
export const setAddressField = 'setAddressField';
export const validateAddressField = 'validateAddressField';
export const setAddressType = 'setAddressType';
export const validateAddressType = 'validateAddressType';
export const setNewsletterChoice = 'setNewsletterChoice';
export const setReceiptChoice = 'setReceiptChoice';
